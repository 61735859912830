/* Start:: Sales Dashboard Styles */
#total-purchase,#net-revenue{
  .apexcharts-datalabels{
    display: none;
  }
}
.circle-icon {
  height: 70px;
  width: 70px;
  position: absolute;
  margin-block-start: -35px;
  margin-inline-start: -9px;
  border-radius: 5px;
}
.card-img-absolute {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin-inline-start: -12px;
}
.icon-user-bx {
  margin-block-start: 18px;
}
.project-content {
  margin-inline-start: 4.5rem;
}

.sales ul.timeline-1:before {
  content: " ";
  margin: 20px 20px 0 22px;
  display: inline-block;
  position: absolute;
  inset-inline-start:2px;
  height: 100%;
  z-index: 0;
  inset-block-end: 0;
  border-inline-start: 2px dotted $light;
}
.sales ul.timeline-1 > li .analytics-icon {
  text-align: center;
  border-radius: 50%;
  position: absolute;
  inset-inline-start: 3px;
  font-size: 19px;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
.sales ul.timeline-1 {
  list-style-type: none;
  position: relative;
}
.sales ul.timeline-1 > li {
  margin: 21.7px 0;
  padding-inline-start: 1.5rem;
}
#balance{
  .apexcharts-toolbar{
    display: none;
  }
}
.weather-card {
  margin: 0 auto;
  height: 492px;
  overflow: hidden;
  border-radius: 5px;
  .top {
    position: relative;
    height: 245px;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(45deg, var(--primary09), rgba(152, 83, 175, 0.8)), url(../images/media/media-5.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
     .wrapper {
      padding: 15px;
      position: relative;
      z-index: 0;
    }
    &:after {
      content: "";
      height: 100%;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
    }
  } 
  .bottom {
    padding: 0 20px;
    background: $custom-white; 
      .wrapper .forecast {
        margin: 0;
        font-size: 0;
        padding: 0;
        padding-block-start: 20px;
        max-height: 155px;
        .active {
          display: block;
          font-size: 16px;
          font-weight: 400;
          line-height: 1em;
          margin-block-end: 18px;
          color: $text-muted;
          padding: 15px;
          border: 1px solid $default-border;
          border-radius: 5px;
        }
      }
  }
  .bottom .wrapper .forecast li .condition {
    display: inline-block;
    vertical-align: middle;
    float: var(--float-right);
    font-size: 20px;
    margin-block-start: -5px; 
    .temp {
      display: inline-block;
      vertical-align: top;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 400;
      padding-block-start: 2px; 
       .temp-type {
        font-size: 20px;
      }
    }
  }
  .top .wrapper .mynav .fe {
    color: #ffffff;
    font-size: 20px;
  }
   .top .wrapper .mynav .fe-chevron-left {
    display: inline-block;
    float: var(--float-left);
  }
  .top .wrapper .temp {
    margin-block-start: 10px;
  } 
  .top .wrapper .temp a {
    text-decoration: none;
    color: #ffffff;
  }
  .top .wrapper .temp a .temp-type {
    font-size: 60px;
  } 
  .top .wrapper .temp .temp-value {
    display: inline-block;
    font-size: 60px;
    font-weight: 500;
    color: #ffffff;
  }
 .uparrow {
      font-size: 17px;
      text-align: center;
      display: block;
      margin-top: 11px;
      color: #7a839b;
  }
  .top .wrapper .temp .deg {
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      vertical-align: top;
      margin-block-start: 0px;
  }
   .top .wrapper .mynav .fe-settings {
      display: inline-block;
      float: var(--float-right);
  }
}
#revenueReport .apexcharts-canvas .apexcharts-toolbar{
  display: none;
}
/* End:: Sales Dashboard Styles */

/* Start:: Project Dashboard Styles */
.project-activity{
  .project.project-timeline {
    position: relative;
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;
    top: 2px;
    &:before {
      content: " ";
      background: $light;
      display: inline-block;
      position: absolute;
      inset-inline-start: 31px!important;
      width: 2px;
      inset-block-start: 15px;
      height: 85%;
      z-index: 0;
    }
  }
  .project.project-timeline>li {
    margin-block-start: 30px;
    margin-inline-end: 0px;
    margin-block-end: 0px;
    margin-inline-start: 42px;
  
    padding-inline-start: 20px;
  }
  .project.project-timeline>li:before {
    content: " ";
    background:$custom-white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid $primary;
    inset-inline-start: 22px !important;
    width: 20px;
    height: 20px;
    z-index: 0;
  }

}
.chips .chip {
  margin: 0 0.5rem 0.65rem 0;
}
.chip {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: $text-muted;
  padding: 0 0.75rem;
  border-radius: 1rem;
  background-color: $light;
  transition: 0.3s background;
}
.chip .avatar {
  float: var(--float-left);
  margin-block-start: 0px;
  margin-inline-end: 0.5rem;
  margin-block-end: 0px;
  margin-inline-start: -0.75rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
/* End:: Project Dashboard Styles */

/* End:: Project Dashboard Styles */

#total-orders,#total-views,#total-earnings,#total-comments{
  .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
    fill: #fff;
  }
} 
.image {
  position: relative;
}
.profile-img {
  height: 180px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}
.img-1 {
  vertical-align: middle;
  position: absolute;
  text-align: center;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: -62px;
}
.img-1 img {
  border-radius: 100%;
  border: 6px solid rgba(225, 225, 225, 0.5);
}
.card .box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.card .box ul li a {
  display: block;
  color: #aaa;
  margin: 0 10px;
  font-size: 20px;
  transition: 0.5s;
  text-align: center;
}
/* End:: Project Dashboard Styles */
/* Start:: Dashboard 4 Styles */
.bitcoin-svg {
  width: 50px;
  height: 50px;
  border: 6px solid #ffffff30;
  border-radius: 35px;
  margin-inline-start: auto !important;
  margin-block-start: 13px;
}
/* End:: Dashboard 4 Styles */

/* Start:: Dashboard 5 Styles */
.square-icon {
  height: 100%;
  width: 85px;
  position: absolute;
  float: var(--float-right);
  line-height: 7;
}
.square-icon .bx-icon {
  display: block;
  margin-block-start: 25px;
}
#visitors-countries {
    height: 16.5rem;
    svg{
        width: 100%;
    }
  
    #jvm-markers-labels-group {
      display: none;
    }
  }
  
  #visitors-countries circle:nth-child(1) {
    fill: $primary;
    stroke: $primary-03;
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(2) {
    fill: $secondary;
    stroke: rgba(var(--secondary-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(3) {
    fill: $danger;
    stroke: rgba(var(--danger-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(4) {
    fill: $info;
    stroke: rgba(var(--info-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(5) {
    fill: $orange;
    stroke: rgba(var(--orange-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(6) {
    fill: $warning;
    stroke: rgba(var(--warning-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(7) {
    fill: $success;
    stroke: rgba(var(--success-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(8) {
    fill: $pink;
    stroke: rgba(var(--pink-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(9) {
    fill: $dark;
    stroke: rgba(var(--dark-rgb), 0.2);
    stroke-width: 15;
  }
  
  #visitors-countries circle:nth-child(10) {
    fill: $indigo;
    stroke: rgba($indigo, 0.2);
    stroke-width: 15;
  }
  #chart{
    .apexcharts-toolbar{
      display: none;
    }
  }
  #totalrevenue,#totalcost,#totalprofit,#totalshipment{
    .apexcharts-canvas, .apexcharts-svg{
      width: auto !important;
    }
  }
/* End:: Dashboard 5 Styles */