#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
}
.error-page {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
}
.error-text {
    font-size: 8.25rem;
    font-weight: 500;
    line-height: 1;
}
@media (max-width: 575.98px) {
    .error-text {
        font-size: 3rem;
    }
}
.zindex-0{
    z-index: 0;
}
.authentication-bg {
    background: url(../images/media/auth-1.jpg);
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-table;
    &::before{
        content: "";
        background-color: $primary-09;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.square-box div:nth-child(1) {
    inset-block-start: 12%;
    inset-inline-start: 42%;
    animation: animate 28s linear infinite;
}

.square-box div {
    position: absolute;
    width: 25px;
    height: 25px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50px;
}

.square-box div:nth-child(2) {
    inset-block-start: 70%;
    inset-inline-start: 50%;
    animation: animate 20s linear infinite;
}

.square-box div:nth-child(4) {
    inset-block-start: 20%;
    inset-inline-start: 60%;
    animation: animate 25s linear infinite;
}

.square-box div:nth-child(3) {
    inset-block-start: 17%;
    inset-inline-start: 6%;
    animation: animate 30s linear infinite;
}

.square-box div:nth-child(5) {
    inset-block-start: 88%;
    inset-inline-start: 10%;
    animation: animate 10s linear infinite;
}

.square-box div:nth-child(6) {
    inset-block-start: 80%;
    inset-inline-start: 70%;
    animation: animate 16s linear infinite;
}

.square-box div:nth-child(7) {
    inset-block-start: 60%;
    inset-inline-start: 80%;
    animation: animate 25s linear infinite;
}

.square-box div:nth-child(8) {
    inset-block-start: 32%;
    inset-inline-start: 25%;
    animation: animate 17s linear infinite;
}

.square-box div:nth-child(9) {
    inset-block-start: 20%;
    inset-inline-start: 80%;
    animation: animate 21s linear infinite;
}

.square-box div:nth-child(10) {
    inset-block-start: 40%;
    inset-inline-start: 72%;
    animation: animate 13s linear infinite;
}

.square-box div:nth-child(11) {
    inset-block-start: 50%;
    inset-inline-start: 30%;
    animation: animate 18s linear infinite;
}

.square-box div:nth-child(12) {
    inset-block-start: 65%;
    inset-inline-start: 16%;
    animation: animate 19s linear infinite;
}

.square-box div:nth-child(13) {
    inset-block-start: 45%;
    inset-inline-start: 3%;
    animation: animate 20s linear infinite;
}

.square-box div:nth-child(14) {
    inset-block-start: 47%;
    inset-inline-start: 55%;
    animation: animate 24s linear infinite;
}

.square-box div:nth-child(15) {
    inset-block-start: 79%;
    inset-inline-start: 90%;
    animation: animate 15s linear infinite;
}

@keyframes animate {
    0% {
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }

    100% {
        transform: scale(2.3) translateY(-90px) rotate(360deg);
        opacity: 0;
    }
}
