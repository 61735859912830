/* Start::body */
body {
  font-size: $default-font-size;
  font-family: $default-font-family;
  font-weight: $default-font-weight;
  background-color: $default-body-color;
  color: $default-text-color;
  line-height: 1.5;
  text-align: start;
  overflow-x: clip;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
/* End::body */

*::-webkit-scrollbar {
  width: 0;
  height: 0.25rem;
  transition: all ease 0.05s;
  background: $light;
}

*:hover::-webkit-scrollbar-thumb {
  background: $light;
}
a,
button {
  outline: 0 !important;
}

/* Start::basic */
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
@media (min-width: 992px) {
  .app-content {
    min-height: calc(100vh - 7.5rem);
    margin-block-end: 0;
    margin-block-start: 3.75rem;
    margin-inline-start: 15rem;
    transition: all 0.05s ease;
  }
}
.main-content {
  padding: 0 0.5rem;
}
.main-content:after {
  content: "";
  height: 190px;
  background: $primary;
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  inset-inline-start: 0;
}
/* End::basic */

/* Start::App Content */

.bd-example > .dropdown-menu {
  position: static;
  display: block;
}

/* Start::width */
.min-w-fit-content {
  min-width: fit-content;
}
/* End::width */

/* Start::Scrollspy */
.scrollspy-example {
  height: 12.5rem;
  margin-block-start: 0.5rem;
  overflow: auto;
}
.scrollspy-example-2 {
  height: 21.875;
  overflow: auto;
}
.scrollspy-example-3 {
  height: 13.75rem;
  overflow: auto;
}
.simple-list-example-scrollspy .active {
  background-color: $primary;
  color: $white;
}
.scrollspy-example-4 {
  height: 12.5rem;
  margin-block-start: 0.5rem;
  overflow: auto;
}
/* End::Scrollspy */

/* Start::Carousel */
.carousel-inner {
  border-radius: 0.35rem;
}
.carousel-caption {
  color: $white;
}
/* End::Carousel */

/* Start::navbar */
.fixed-top {
  position: static;
  margin: -1rem -1rem 0rem -1rem;
}

.fixed-bottom {
  position: static;
  margin: 1rem 0rem -1rem -1rem;
}

.sticky-top {
  position: static;
  margin: -1rem -1rem 0rem -1rem;
}
/*End::navbar*/

/* Start::Helpers */
.bd-example-ratios .ratio {
  display: inline-block;
  width: 10rem;
  color: $text-muted;
  background-color: $primary-01;
  border: $default-border;
  border-radius: $default-radius;
}

@media (min-width: 768px) {
  .bd-example-ratios-breakpoint .ratio-4x3 {
    --bs-aspect-ratio: 50%;
  }
}

.bd-example-ratios-breakpoint .ratio-4x3 {
  width: 16rem;
}
/* End::Helpers */

/* Start::Layouts */
.bd-example-row [class^="col"],
.bd-example-cssgrid .grid > * {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
  background-color: rgba(var(--light-rgb), 0.75);
  border: 1px solid $default-border;
}
.bd-example-cssgrid .grid > * {
  border-radius: 0.25rem;
}
.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(var(--light-rgb), 0.5);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);

  .g-start-2 {
    grid-column-start: 2 !important;
  }

  .g-col-2 {
    grid-column: auto/span 2;
  }

  .g-start-3 {
    grid-column-start: 3;
  }

  .g-col-3 {
    grid-column: auto/span 3;
  }

  .g-col-4,
  .g-col-md-4 {
    grid-column: auto/span 4;
  }

  .g-col-6 {
    grid-column: auto/span 6;
  }

  .g-start-6 {
    grid-column-start: 6;
  }
}
/* End::Layouts */

.callout {
  padding: 1.25rem;
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  background-color: $light;
  border-inline-start: 0.25rem solid $default-border;
}

.callout-info {
  background-color: rgba(var(--info-rgb), 0.075);
  border-color: rgba(var(--info-rgb), 0.5);
}

.callout-warning {
  background-color: rgba(var(--warning-rgb), 0.075);
  border-color: rgba(var(--warning-rgb), 0.5);
}

.callout-danger {
  background-color: rgba(var(--danger-rgb), 0.075);
  border-color: rgba(var(--danger-rgb), 0.5);
}

.flex-container div {
  background-color: transparent;
  border: 0;
  > div {
    background-color: $light;
    border: 1px solid $custom-white;
  }
}

.bd-example-position-utils {
  position: relative;
  padding: 2rem;

  .position-relative {
    height: 12.5rem;
    background-color: $default-background;
  }

  .position-absolute {
    width: 2rem;
    height: 2rem;
    background-color: $primary-03;
    border-radius: 0.2rem;
  }
}
/* End::Utilities Page */

/* Start:: Images & Figures */
.bd-placeholder-img-lg {
  font-size: 3.5rem;
}
.figure-caption {
  color: $text-muted;
}
/* End:: Images & Figures */

/* End:App-Content */

/*  Start::Footer*/
@media (min-width: 992px) {
  .footer {
    padding-inline-start: 15rem;
  }
}

.footer {
  box-shadow: 0 0.25rem 1rem $black-1;
}
/*  End::Footer*/

/* Start::OffCanvas */
.offcanvas {
  background-color: $custom-white;
  color: $default-text-color;
  border-color: $default-border !important;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}
/* End::OffCanvas */

/* Start::Switcher */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#switcher-main-tab {
  border-block-end: 0;
}
#switcher-canvas {
  width: 27.5rem;
  .offcanvas-body {
    padding: 0 0 4.75rem 0;
  }
  .canvas-footer {
    padding: 0.75rem 1.563rem;
    position: absolute;
    inset-block-end: 0;
    width: 100%;
    background-color: $custom-white;
    border-block-start: 1px dashed $default-border;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  }
  #switcher-main-tab button.nav-link {
    border-radius: 0;
    color: $default-text-color;
    font-weight: 600;
    &.active {
      color: $success;
      background-color: rgba(var(--success-rgb), 0.2);
      border-color: transparent;
    }
    &:hover {
      border-color: transparent;
    }
  }
}
.switcher-style {
  padding: 0.875rem 1.563rem;
  h6 {
    margin-block-end: 0.625rem;
  }
}
.switcher-icon i {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.switch-select {
  .form-check-label {
    font-size: 0.813rem;
    font-weight: 600;
  }
  &.form-check {
    margin-block-end: 0;
    min-height: auto;
  }
}
.menu-image {
  .bgimage-input {
    width: 3.5rem;
    height: 5.625rem;
    border-radius: $default-radius;
    border: 0;
    &.form-check-input:focus {
      border-color: transparent;
      box-shadow: 0 0 0 0.25rem $black-1;
    }
    &.bg-img1 {
      background-image: url(../images/menu-bg-images/bg-img1.jpg) !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &.form-check-input:checked[type="radio"] {
        background-color: none;
      }
    }
    &.bg-img2 {
      background-image: url(../images/menu-bg-images/bg-img2.jpg) !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &.form-check-input:checked[type="radio"] {
        background-color: none;
      }
    }
    &.bg-img3 {
      background-image: url(../images/menu-bg-images/bg-img3.jpg) !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &.form-check-input:checked[type="radio"] {
        background-color: none;
      }
    }
    &.bg-img4 {
      background-image: url(../images/menu-bg-images/bg-img4.jpg) !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &.form-check-input:checked[type="radio"] {
        background-color: none;
      }
    }
    &.bg-img5 {
      background-image: url(../images/menu-bg-images/bg-img5.jpg) !important;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &.form-check-input:checked[type="radio"] {
        background-color: none;
      }
    }
  }
}
.theme-colors {
  &.switcher-style {
    padding: 0.938rem 1.563rem;
  }
  .switch-select {
    .color-input {
      width: 2rem;
      height: 2rem;
      border-radius: 50px;
      &.form-check-input:checked {
        border: 1px solid $input-border;
        position: relative;
        box-shadow: $box-shadow;
        &:before {
          position: absolute;
          content: "\ea5e";
          font-family: tabler-icons !important;
          color: $success;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.35rem;
          font-weight: 600;
        }
      }
      &.color-white {
        background-color: $white;
      }
      &.color-dark {
        background-color: $black;
      }
      &.color-primary {
        background-color: $primary;
      }
      &.color-primary-1 {
        background-color: rgb(58, 88, 146);
      }
      &.color-primary-2 {
        background-color: rgb(92, 144, 163);
      }
      &.color-primary-3 {
        background-color: rgb(161, 90, 223);
      }
      &.color-primary-4 {
        background-color: rgb(78, 172, 76);
      }
      &.color-primary-5 {
        background-color: rgb(223, 90, 90);
      }
      &.color-gradient {
        background-image: linear-gradient(to right top, $primary, #6e72a8);
      }
      &.color-transparent {
        background-image: url(../images/menu-bg-images/transparent.png) !important;
      }
      &.color-bg-1 {
        background-color: rgb(20, 30, 96);
      }
      &.color-bg-2 {
        background-color: rgb(8, 78, 115);
      }
      &.color-bg-3 {
        background-color: rgb(90, 37, 135);
      }
      &.color-bg-4 {
        background-color: rgb(24, 101, 51);
      }
      &.color-bg-5 {
        background-color: rgb(120, 66, 20);
      }
    }
    .form-check-input:checked[type="radio"] {
      background-image: none;
    }
    .form-check-input:focus {
      box-shadow: none;
    }
    .form-check-input:active {
      filter: brightness(100%);
    }
  }
}
.switcher-style-head {
  font-size: 0.8rem;
  font-weight: 600;
  margin-block-end: 0;
  background: $light;
  padding: 0.313rem 0.625rem;
  color: $default-text-color;
  .switcher-style-description {
    float: right;
    font-size: 0.625rem;
    background-color: rgba(var(--secondary-rgb), 0.2);
    color: $secondary;
    padding: 0.125rem 0.313rem;
    border-radius: $default-radius;
  }
}
#switcher-home,
#switcher-profile {
  padding: 0;
}
.theme-container-primary,
.theme-container-background {
  button {
    display: none;
  }
}
.pickr-container-primary,
.pickr-container-background {
  .pickr .pcr-button {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $input-border;
    &:focus {
      box-shadow: none;
    }
    &::after {
      content: "\EFC5";
      font-family: remixicon !important;
      color: rgba($white, 0.7);
      line-height: 1.5;
      font-size: 1.25rem;
    }
  }
}
/* End::Switcher */

/* Start::Bootstrap Styles */
.card {
  background-color: $custom-white;
  border: 1px solid $bootstrap-card-border;
}
.img-thumbnail {
  background-color: $custom-white;
  border: 1px solid $default-border;
}

/* End::Bootstrap Styles */

/* Start::Dashboard Apex Charts  */
#btcCoin,
#ethCoin,
#dshCoin,
#glmCoin {
  .apexcharts-grid,
  .apexcharts-xaxis {
    line {
      stroke: transparent;
    }
  }
}
.apexcharts-title-text {
  fill: $text-muted;
}
/* Start::Dashboard Apex Charts  */

/* Start::Switcher */
@media (max-width: 991.98px) {
  .navigation-menu-styles {
    display: none;
  }
}
/* Start::Switcher */

/* Start:Responsive Dropdowns */
@media (max-width: 575.98px) {
  .cart-dropdown,
  .timelines-dropdown,
  .header-fullscreen {
    display: none !important;
  }
}
/* End:Responsive Dropdowns */

/* Start::Close Button */
.btn-close:focus {
  box-shadow: none !important;
}
/* End::Close Button */

/* Start::Icons Page */
.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  .icons-list-item {
    text-align: center;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $default-border;
    margin: 0.25rem;
    border-radius: 0.25rem;
    i {
      font-size: 1.05rem;
      color: $text-muted;
    }
  }
}
// .fe {
//   font-size: inherit !important;
// }
/* End::Icons Page */

.bd-placeholder-img {
  margin: 0.125rem;
}

/* Start::Shadows */
.shadow-primary {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-rgb), 0.3) !important;
}
.shadow-secondary {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3) !important;
}
.shadow-success {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.3) !important;
}
.shadow-info {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.3) !important;
}
.shadow-warning {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.3) !important;
}
.shadow-danger {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.3) !important;
}
.shadow-orange {
  box-shadow: 0 0.25rem 1rem rgba(var(--orange-rgb), 0.3) !important;
}
.shadow-purple {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.3) !important;
}
/* End::Shadows */

/* Start::placeholders */
.placeholder-xl {
  min-height: 1.5em;
}
.placeholder {
  background-color: $gray-7;
}
/* End:::placeholders */

/* Start::scrollspy */
.scrollspy-example-2 {
  height: 21.875rem;
  border: 1px solid $default-border;
  padding: 0.75rem;
  border-radius: $default-radius;
}
/* End::scrollspy */

/* Start::object-fit */
.object-fit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  img,
  video {
    width: 15.625rem;
    height: 15.625rem;
  }
}
/* End::object-fit */

/* Start::invoice */
.invoice-amount-input {
  width: 9.375rem;
}
.choices-control {
  .choices__inner {
    background-color: $light !important;
    border: 0 !important;
  }
}
.svg-icon-background {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem;
  border-radius: $default-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.invoice-quantity-container {
  width: 8.75rem;
}
/* End::invoice */

/* Start::pricing */

.panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
  
	> .panel-heading {
	  -moz-transition: all .3s ease;
	  -o-transition: all .3s ease;
	  -webkit-transition: all .3s ease;
	}
  
	h3 {
	  margin-block-end: 0;
	  padding: 20px 0;
	}
  }
  
  .panel-heading {
	padding: 10px 15px;
	border-block-end: 1px solid $white-2;
	background: $white;
	border-radius: 6px 6px 0 0;
  }
  
  .panel.price {
	> .panel-heading {
	  color: $white;
	}
  
	.list-group-item {
	  &:last-child {
		border-end-end-radius: 0px;
		border-end-start-radius: 0px;
	  }
  
	  &:first-child {
		border-start-end-radius: 0px;
		border-start-start-radius: 0px;
	  }
	}
  
	margin-block-end: 1.5rem;
  }
  
  .price {
    .panel-footer {
      border-block-end: 0px;
      background-color: $custom-white;
      border-inline-start: 0;
      border-inline-end: 0;
    }
    
    &.panel-color > .panel-body {
      background-color: $custom-white;
    }
  }
  .card-category {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin: 0 0 0.5rem;
    background: $light;
}
  .panel-body {
	padding: 15px;
  
	.lead {
	  strong {
		font-size: 40px;
		margin-block-end: 0;
	  }
  
	  font-size: 20px;
	  margin-block-end: 0;
	  padding: 10px 0;
	}
  }
  
  .panel-footer {
	padding: 10px 15px;
	background-color: $custom-white;
	border-block-start: 1px solid $default-border;
	border-inline-start: 1px solid $default-border;
	border-inline-end: 1px solid $default-border;
	border-end-end-radius: 3px;
	border-end-start-radius: 3px;
  }
  
  .panel.price .btn {
	border: 0px;
  }
  
  .pricing .list-unstyled li {
	border-block-end: 1px solid $default-border;
	display: flow-root;
	margin-block-end: 0;
	padding: 9px 0;
  }
  
  .pricing-card {
	.list-unstyled {
	  padding-inline-start: 0;
	  list-style: none;
	}
  
	.display-5 {
	  font-size: 2.5rem;
	  font-weight: 300;
	  line-height: 1.1;
	}
  
	.list-unstyled li {
	  border-block-end: 1px solid $default-border;
	  display: flow-root;
	  margin-block-end: 0;
	  padding: 9px 0;
	}
  }
  .pricing .card-category {
      background:$white-3;
      padding: 10px 0;
      color: $white;
 }
 .pricing .list-unstyled li {
     border-block-end: 1px solid $white-1;
     padding: 7px 0;
}

/* End::pricing */

/* Start::Team */
.teams-nav {
  max-height: calc(100vh - 9rem);
}
.team-dropdown{
  position: absolute;
  inset-block: 15px;
}
.teams-nav ul li {
  padding: 0.625rem 1.25rem;
}
.team-member-card {
  overflow: hidden;
  .avatar {
    position: absolute;
    inset-block-start: 65%;
    inset-inline-start: 38%;
    outline: 0.25rem solid rgba(0, 0, 0, 0.075);
  }
  @media (min-width: 576px) {
    .team-member-stats {
      div {
        border-inline-end: 1px dashed $default-border;
        width: 100%;
        &:last-child {
          border-inline-end: 0;
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    .team-member-stats {
      div {
        border-block-end: 1px dashed $default-border;
        &:last-child {
          border-block-end: 0;
        }
      }
    }
  }
  @media (min-width: 1400px) and (max-width: 1700px) {
    .team-member-details {
      max-width: 12rem;
    }
  }
  .teammember-cover-image {
    position: relative;
    .card-img-top {
      width: 100%;
      height: 6.25rem;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $primary-07;
      inset-inline-start: 0;
      inset-inline-end: 0;
      inset-block-start: 0;
      inset-block-end: 0;
    }
    .team-member-star {
      position: absolute;
      inset-block-start: 0.75rem;
      padding: 0.375rem;
      inset-inline-end: 0.75rem;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: $default-radius;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
/* End::Team */

/* Start:: Contacts */
.contact-action {
  position: relative;
  padding: 1.25rem;
  .contact-overlay {
    position: absolute;
    width: 0;
    height: 100%;
    background-color: $primary-07;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    z-index: 1;
    border-radius: $default-radius;
  }
  &:hover {
    .contact-overlay {
      width: 100%;
      height: 100%;
      top: 0;
      inset-inline-start: 0;
    }
    .contact-hover-btn,
    .contact-hover-dropdown,
    .contact-hover-dropdown1 {
      display: block;
      opacity: 1;
    }
  }
  .contact-hover-btn {
    opacity: 0;
    z-index: 2;
  }
  .contact-hover-dropdown {
    opacity: 0;
    z-index: 2;
  }
  .contact-hover-dropdown1 {
    opacity: 0;
    z-index: 2;
  }
  .contact-hover-buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
  }
}
ul {
  list-style-type: none;
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .contact-mail {
    max-width: 8.125rem;
  }
}
@media (max-width: 400px) {
  .contact-mail {
    max-width: 8.125rem;
  }
}
.ht-390 {
  height: 390px;
}
.flex-1 {
  flex: 1;
}
/* Start:: Contacts */

/* Start:: Notifications */
.notification-container {
  li .un-read {
    border-inline-start: 0.25rem solid $primary-03;
  }
}
/* End:: Notifications */

/* Start:: To Do Task */
.task-navigation ul.task-main-nav li {
  padding: 0.5rem;
  border-radius: var(--default-border);
  border-radius: 0.3rem;
  font-weight: 500;
  &:hover a {
    color: $primary;
  }
  &.active div {
    color: $primary;
  }
}
.task-pending-card,
.task-inprogress-card,
.task-completed-card {
  .card-body {
    position: relative;
    .badge {
      position: absolute;
      inset-block-end: 1.25rem;
      inset-inline-end: 1.25rem;
    }
  }
  &:hover {
    box-shadow: 0 0.25rem 1rem $black-1;
  }
}
.task-tabs-container {
  .tab-pane {
    min-height: calc(100vh - 22rem);
    border: 0;
  }
}
/* End:: To Do Task */

/* Start:: Terms & Conditions */
.terms-conditions {
  max-height: 44.75rem;
}
.terms-heading {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 0.25rem;
    background: linear-gradient(to right, $primary, $primary-01);
    inset-inline-start: 25%;
    inset-block-end: -0.65rem;
    border-radius: $default-radius;
  }
}
.card.card-fullscreen {
  border-radius: 0;
}
/* End:: Terms & Conditions */

/* Start:: Contact Us */
@media (min-width: 576px) {
  .contactus-form {
    position: relative;
    inset-block-start: -6.25rem;
  }
  .wd-sm-200 {
    width: 200px;
}
}
@media (max-width: 575px) {
  .contact-page-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block-start: 0;
  }
}
.contactus-form .tab-style-2 .nav-item .nav-link {
  padding: 0.85rem 2rem;
}
.contactus-form iframe {
  width: 100%;
}
/* End:: Contact Us */

/* Start:: Reviews */
.reviews-container {
  .card {
    position: relative;
    &:before {
      content: "\ec52";
      font-family: remixicon !important;
      position: absolute;
      width: 2.188rem;
      height: 2.188rem;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      border-radius: 3.125rem;
      justify-content: center;
      color: $white;
      background-color: $primary;
      box-shadow: 0 0 0.5rem $primary-05;
      inset-inline-end: 1.125rem;
      inset-block-start: -1.125rem;
    }
  }
}
/* End:: Reviews */

/* Start:: Faq's */
#accordionFAQ {
  max-height: 31.25rem;
}
/* End:: Faq's */

/* Start:: Timeline */


/* ###### Timeline  ###### */

.vtimeline {
	list-style: none;
	padding: 0;
	position: relative;
	margin-block-end: 20px;
  
	&:before {
	  top: 16px;
	  bottom: 7rem;
	  position: absolute;
	  content: " ";
	  width: 2px;
	  background-color: $default-border;
	  inset-inline-start: 50%;
	  margin-inline-start: -1.5px;
	}
  
	.timeline-wrapper {
	  display: block;
	  margin-block-end: 20px;
	  position: relative;
	  width: 100%;
	  padding-inline-end: 90px;
  
	  &:before {
		content: " ";
		display: table;
	  }
  
	  &:after {
		content: " ";
		display: table;
		clear: both;
	  }
  
	  .timeline-panel {
		border-radius: $default-radius;
		padding: 20px;
		position: relative;
		background: $custom-white;
		box-shadow: 0 0 30px 0 rgba(162, 169, 204, 0.05);
		width: 35%;
		margin-inline-start: 15%;
    border: 1px solid $default-border;
  
		&:before {
		  position: absolute;
		  top: 0;
		  width: 100%;
		  height: 2px;
		  content: "";
		  inset-inline-start: 0;
		  inset-inline-end: 0;
		}
  
		&:after {
		  position: absolute;
		  top: 10px;
		  inset-inline-end: -14px;
		  display: inline-block;
		  border-block-start: 14px solid transparent;
		  border-inline-start: 14px solid $custom-white;
		  border-inline-end: 0 solid $custom-white;
		  border-block-end: 14px solid transparent;
		  content: " ";
		}
  
		.timeline-title {
		  font-size: 1rem;
		  font-weight: 700;
		  margin: 0 0 .625rem;
		}
  
		.timeline-body {
		  p + p {
			margin-block-start: 5px;
		  }
  
		  ul {
			margin-block-end: 0;
		  }
		}
  
		.timeline-footer span {
		  font-size: .6875rem;
		}
	  }
  
	  .timeline-badge {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 16px;
		inset-inline-start: calc(50% - 10px);
		z-index: 0;
		border-start-end-radius: 50%;
		border-start-start-radius: 50%;
		border-end-end-radius: 50%;
		border-end-start-radius: 50%;
		border: 5px solid $white-8;
  
		i {
		  color: $white;
		}
	  }
  
	  &.timeline-inverted {
		padding-inline-end: 0;
		padding-inline-start: 90px;
  
		.timeline-panel {
		  margin-inline-start: auto;
		  margin-inline-end: 15%;
  
		  &:after {
			border-inline-start-width: 0;
			border-inline-end-width: 14px;
			inset-inline-start: -14px;
			inset-inline-end: auto;
		  }
		}
	  }
	}
  }
  
  .timeline-wrapper-primary {
	.timeline-panel:before, .timeline-badge {
	  background: $primary;
	}
  }
  
  .timeline-wrapper-secondary {
	.timeline-panel:before, .timeline-badge {
	  background: #eb6f33;
	}
  }
  
  .timeline-wrapper-success {
	.timeline-panel:before, .timeline-badge {
	  background: $success;
	}
  }
  
  .timeline-wrapper-info {
	.timeline-panel:before, .timeline-badge {
	  background: #01b8ff;
	}
  }
  
  .timeline-wrapper-warning {
	.timeline-panel:before, .timeline-badge {
	  background: #ffc107;
	}
  }
  
  .timeline-wrapper-danger {
	.timeline-panel:before, .timeline-badge {
	  background: $danger;
	}
  }
  
  .timeline-wrapper-light {
	.timeline-panel:before, .timeline-badge {
	  background: #f1f2f9;
	}
  }
  
  .timeline-wrapper-dark {
	.timeline-panel:before, .timeline-badge {
	  background: $pink;
	}
  }
  .wd-20 {
    width: 20px;
}
  .ht-20 {
      height: 20px;
  }
  
  @media (max-width: 767px) {
	.timeline .btn {
	  font-size: 12px;
	}
  
	.vtimeline {
	  &:before {
		inset-inline-start: 6px !important;
	  }
  
	  .timeline-wrapper {
		.timeline-badge {
		  inset-inline-start: 0 !important;
		}
  
		padding-inline-end: 0 !important;
  
		.timeline-panel {
		  width: auto !important;
		  margin-inline-start: 14% !important;
  
		  &:after {
			border-inline-end: 14px solid #cad4e4 !important;
			border-inline-start: 0 solid #cad4e4 !important;
			inset-inline-start: -14px !important;
			inset-inline-end: auto !important;
		  }
		}
  
		&.timeline-inverted {
		  padding-inline-start: 0 !important;
  
		  .timeline-panel {
			margin-inline-end: 0 !important;
			width: auto !important;
		  }
		}
	  }
	}
  }
  img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

  /* ###### Timeline End ###### */

/* Start:: Blog */
.blog-caption {
  position: absolute;
  inset-inline-start: 1.5rem;
  inset-block-end: 1.5rem;
  padding: 0;
}
.popular-blog-content {
  max-width: 9rem;
}
/* End:: Blog */

/* Start:: Blog Details */
.blog-details-img {
  height: 450px;
}
.blog-popular-tags {
  .badge {
    margin: 0.313rem;
    font-size: 0.65rem;
  }
}
/* End:: Blog Details */

/* Start:: Create Blog */
.blog-images-container {
  .filepond--root {
    width: 100%;
  }
  .filepond--panel-root {
    border-color: $input-border !important;
    border-radius: $default-radius;
  }
  .filepond--root .filepond--drop-label {
    label {
      color: $text-muted;
    }
  }
}
#blog-content {
  height: auto;
}
@media screen and (max-width: 400px) {
  .choices__inner .choices__list--multiple .choices__item {
    margin-block-end: 0.25rem !important;
  }
}
@media screen and (max-width: 991px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-block-end: 0.25rem !important;
  }
}

/* End:: Create Blog */

/* Start:: Profile */
.main-profile-cover {
  background-image: url(../images/media/media-6.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 9;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primary-07;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
  }
}
.main-profile-info {
  z-index: 10;
  position: relative;
}
.profile-works img {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 0.3rem;
  margin-bottom: 1.25rem;
}
.profile-timeline {
  margin-block-end: 0;
  li {
    padding-inline-start: 5.313rem;
    position: relative;
    margin-block-end: 1.75rem;
    .profile-timeline-avatar {
      position: absolute;
      inset-inline-start: 2.125rem;
      inset-block-start: 0.188rem;
    }
    &:last-child {
      margin-block-end: 0;
      &::before {
        display: none;
      }
    }
    &::before {
      content: "";
      background-color: transparent;
      border: 1px dashed rgba(var(--dark-rgb), 0.1);
      height: 100%;
      position: absolute;
      inset-inline-start: 2.813rem;
      inset-block-start: 1.813rem;
    }
  }
  .profile-activity-media {
    img {
      width: 4rem;
      height: 3rem;
      border-radius: $default-radius;
      margin: 0.25rem;
    }
  }
}
.profile-recent-posts {
  max-width: 10rem;
}
.profile-post-content {
  width: 42rem;
}
#profile-posts-scroll {
  max-height: 35rem;
}
.profile-post-link {
  @media (max-width: 420px) {
    max-width: 9.375rem;
  }
}
/* End:: Profile */

/* Start:: Aboutus */
.about-container {
  background-color: $custom-white;
  border-radius: $default-radius;
  overflow: hidden;
  padding: 1.25rem;
}
@media (max-width: 575.98px) {
  .about-company-stats {
    .about-company-stats-border {
      border-block-end: 1px dashed $black-1;
    }
  }
}
@media (min-width: 576px) {
  .about-company-stats {
    position: absolute;
    bottom: -5rem;
    z-index: 10;
    inset-inline-start: 0;
    inset-inline-end: 0;
    .about-company-stats-border {
      border-inline-end: 1px dashed $black-1;
    }
  }
  .aboutus-banner {
    padding: 4rem;
    margin-block-end: 8rem;
  }
}
.about-heading-white {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.25rem;
    border-radius: 3.125rem;
    background-image: linear-gradient(
      to right,
      $white,
      rgba(255, 255, 255, 0.2)
    );
    bottom: -0.625rem;
    inset-inline-start: 0.375rem;
  }
}

@media (max-width: 1199.98px) {
  .aboutus-banner-img {
    display: none;
  }
}
@media (min-width: 1200px) {
  .aboutus-banner-img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 12.5rem;
      height: 12.5rem;
      inset-block-start: 6rem;
      inset-inline-start: 11.875rem;
      background-color: rgba(255, 255, 255, 0.06);
    }
    &:after {
      position: absolute;
      content: "";
      width: 12.5rem;
      top: -2.625rem;
      inset-inline-end: 17.5rem;
      height: 12.5rem;
      background-color: rgba(255, 255, 255, 0.06);
    }
    img {
      width: 100%;
      height: 17.5rem;
      z-index: 1;
    }
  }
}
.about-main,
.about-motto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.motto-icon {
  width: 1.875rem;
  height: 1.875rem;
}
.about-heading {
  position: relative;
  margin-block-end: 1rem;
  &:before {
    content: "";
    position: absolute;
    width: 60%;
    height: 0.25rem;
    border-radius: 3.125rem;
    background-image: linear-gradient(
      to right,
      $success,
      rgba(var(--success-rgb), 0.2)
    );
    inset-block-end: -0.625rem;
    inset-inline-start: 0.375rem;
  }
}
/* End:: Aboutus */

/* Start:: Full Calendar */
[dir="rtl"] {
  .fullcalendar-events-activity {
    li {
      padding: 0.25rem 2rem 0.25rem 1rem;
    }
  }
}
.fullcalendar-events-activity {
  li {
    margin-block-end: 0.75rem;
    font-size: 0.8125rem;
    padding: 0.25rem 1rem 0.25rem 2rem;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 0.75rem;
      height: 0.75rem;
      border: 0.125rem solid $primary-03;
      border-radius: 3.125rem;
      background-color: $custom-white;
      inset-inline-start: 0.25rem;
      inset-block-start: 0.563rem;
    }
    &::after {
      position: absolute;
      content: "";
      height: 100%;
      background-color: transparent;
      border-inline-end: 2px dashed $primary-01;
      inset-inline-start: 0.563rem;
      inset-block-start: 1.25rem;
    }
    &:last-child {
      margin-block-end: 0;
      &::after {
        border-inline-end: 0px dashed $default-border;
      }
    }
  }
}
#full-calendar-activity {
  max-height: 21rem;
}
/* End:: Full Calendar */

/* Start:: Draggable Cards */
#draggable-left,
#draggable-right {
  .card {
    cursor: move;
  }
}
/* End:: Draggable Cards */

/* Start:: Back to Top */
.scrollToTop {
  background-color: $primary;
  color: $white;
  position: fixed;
  bottom: 20px;
  inset-inline-end: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
  z-index: 10000;
  height: 2.5rem;
  width: 2.5rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  border-radius: $default-radius;
  box-shadow: rgba(100, 100, 110, 0.2) 0 0.438rem 1.813rem 0;
}
/* End:: Back to Top */

[dir="rtl"] {
  .recruiter {
    .btn-icon.btn-sm {
      transform: rotate(180deg);
    }
  }
  .rtl-rotate {
    transform: rotate(180deg);
  }
}
#address-add {
  min-height: calc(1.5em + 2.75rem + calc(var(--bs-border-width) * 2));
}

/* Start:: Loader */
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $custom-white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
/* End:: Loader */

/* Start:: Offcanvas body padding*/
@media (min-width: 992px) {
  body {
    padding-right: 0 !important;
    overflow: auto !important;
  }
}
/* end:: Offcanvas body padding*/

/* Start:: Bootstrap Updated Styles */

[data-theme-mode="dark"] .bg-dark .h1,
[data-theme-mode="dark"] .bg-dark .h2,
[data-theme-mode="dark"] .bg-dark .h3,
[data-theme-mode="dark"] .bg-dark .h4,
[data-theme-mode="dark"] .bg-dark .h5,
[data-theme-mode="dark"] .bg-dark .h6,
[data-theme-mode="dark"] .bg-dark h1,
[data-theme-mode="dark"] .bg-dark h2,
[data-theme-mode="dark"] .bg-dark h3,
[data-theme-mode="dark"] .bg-dark h4,
[data-theme-mode="dark"] .bg-dark h5,
[data-theme-mode="dark"] .bg-dark h6,
[data-theme-mode="dark"] .card-bg-dark .h1,
[data-theme-mode="dark"] .card-bg-dark .h2,
[data-theme-mode="dark"] .card-bg-dark .h3,
[data-theme-mode="dark"] .card-bg-dark .h4,
[data-theme-mode="dark"] .card-bg-dark .h5,
[data-theme-mode="dark"] .card-bg-dark .h6,
[data-theme-mode="dark"] .card-bg-dark h1,
[data-theme-mode="dark"] .card-bg-dark h2,
[data-theme-mode="dark"] .card-bg-dark h3,
[data-theme-mode="dark"] .card-bg-dark h4,
[data-theme-mode="dark"] .card-bg-dark h5,
[data-theme-mode="dark"] .card-bg-dark h6 {
  color: $custom-white !important;
}
[data-theme-mode="dark"] .bg-dark .text-fixed-white {
  color: $white !important;
}

[data-theme-mode="dark"] .bg-dark.card.custom-card,
[data-theme-mode="dark"] .card-bg-dark.card.custom-card {
  color: $custom-white !important;
}
[data-theme-mode="dark"] .bg-dark.card.custom-card .card-body,
[data-theme-mode="dark"] .bg-dark.card.custom-card .card-footer,
[data-theme-mode="dark"] .card-bg-dark.card.custom-card .card-body,
[data-theme-mode="dark"] .card-bg-dark.card.custom-card .card-footer {
  color: $custom-white !important;
}
// [data-theme-mode="dark"] .bg-light,
// [data-theme-mode="dark"] .card-bg-light {
//   color: $custom-black !important;
// }
[data-theme-mode="dark"] .choices[data-type*="select-one"] .choices__button {
  filter: invert(1);
}
[data-theme-mode="dark"] #circle-custom .apexcharts-legend.apx-legend-position-left {
  background-color: $custom-white;
}
[data-theme-mode="dark"] .navbar-nav .nav-link.active,
[data-theme-mode="dark"] .navbar-nav .nav-link.show {
  color: $white;
}

.d-inline-table {
  display: inline-table;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default-text-color;
}

.table > :not(caption) > * > * {
  --bs-body-bg: var(--custom-white);
  --bs-table-bg: var(--custom-white);
  // background-color: $custom-white !important;
  color: $default-text-color;
}

.card.custom-card {
  color: $default-text-color !important;
}

[data-theme-mode="light"][data-header-styles="transparent"]
  .page-header-breadcrumb
  h4,
[data-theme-mode="light"][data-default-header-styles="transparent"]
  .page-header-breadcrumb
  h4 {
  color: $default-text-color !important;
}
[data-theme-mode="light"][data-header-styles="transparent"]
  .app-header
  .main-header-container
  .form-control,
[data-theme-mode="light"][data-default-header-styles="transparent"]
  .app-header
  .main-header-container
  .form-control {
  background: rgba(0, 0, 0, 0.05) !important;
  color: $default-text-color;
  box-shadow: none;
}
[data-theme-mode="light"] .page-header-breadcrumb h4 {
  color: $white !important;
}

[data-header-styles="dark"] .header-link.dropdown-toggle .user-name,
[data-header-styles="color"] .header-link.dropdown-toggle .user-name,
[data-header-styles="gradient"] .header-link.dropdown-toggle .user-name {
  color: $white !important;
}

[data-default-header-styles="transparent"] .page-header-breadcrumb,
[data-default-header-styles="light"] .page-header-breadcrumb,
[data-default-header-styles="dark"] .page-header-breadcrumb,
[data-default-header-styles="color"] .page-header-breadcrumb,
[data-default-header-styles="gradient"] .page-header-breadcrumb {
  margin: 0px 0px -32px 0px;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  background-color: transparent !important;
}

.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: transparent !important;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: $text-muted !important;
  font-weight: 600;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: $text-muted !important;
  font-weight: 600;
}

.dropdown-item-text {
  color: $default-text-color !important;
}
.blockquote-footer {
  color: $default-text-color !important;
}
hr {
  border-color: $default-border;
  opacity: 1;
}
/* End:: Bootstrap Updated Styles */

/* Start:: rtl  */
[dir="rtl"] {
  .dropdown-menu {
    --bs-position: start;
  }
  .dropdown-menu-end {
    --bs-position: end;
  }
  .transform-arrow {
    transform: rotate(180deg);
  }
  .reviews-container .card:before {
    transform: rotate(180deg);
  }
  .bi-skip-backward::before {
    content: "\f55e";
  }
  .bi-skip-forward::before {
    content: "\f552";
  }
  .fa-angle-right:before {
    content: "\f104";
  }
  .fa-angle-left:before {
    content: "\f105";
  }
  .ri-arrow-right-s-line:before {
    content: "\ea64";
  }
  .ri-arrow-left-s-line:before {
    content: "\ea6e";
  }
}
/* End:: rtl  */

  /*  Position Start   */
.pos-static {
	position: static !important;
  }
  
  .pos-relative {
	position: relative !important;
  }
  
  .pos-absolute {
	position: absolute !important;
  }
  
  .pos-fixed {
	position: fixed !important;
  }
  
  .pos-sticky {
	position: sticky !important;
  }
  /*  Position End   */

  /*  Userlist Start   */
  .panel-heading {
    padding: 10px 15px;
    border-block-end: 1px solid rgba(255, 255, 255, 0.2);
    background: $custom-white;
    border-radius: 6px 6px 0 0;
  }
  .panel-footer {
    padding: 10px 15px;
    background-color: $custom-white;
    border-block-start: 1px solid $default-border;
    border-inline-start: 1px solid $default-border;
    border-inline-end: 1px solid $default-border;
    border-end-end-radius: 3px;
    border-end-start-radius: 3px;
  }
  /*  Userlist End   */
  .img-thumbnail {
    padding: 0.25rem;
    background-color: $custom-white;
    border: 1px solid $default-border;
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
    margin: 5px;
}
.thumbimg{
  border-radius: 0.5rem;
}
.wd-100p {
  width: 100%;
}

/* Start::Settings */
@media (min-width: 992px){
  .main-content-left-mail {
      display: block;
  }
  .main-settings-menu .nav-link {
    font-size: 14px;
  }
  .main-settings-menu .nav-link i {
      font-size: 18px !important;
  }
}
  .main-nav-column {
      flex-direction: column;
  }
  .main-nav-column .nav-link.active {
    position: relative;
      color: var(--primary-bg-color) !important;
  }
  .main-nav-column .nav-link {
    padding: 0;
    height: 38px;
    color: $text-muted;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400 !important;
  }
.main-nav-column .nav-link i {
  font-size: 18px;
  line-height: 0;
  width: 24px;
  margin-inline-end: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.main-nav-column .nav-link.active{
  background-color: transparent;
  color: $primary !important;
}
.settings-main-icon i {
  width: 3rem;
  font-size: 18px !important;
  line-height: 3rem;
  height: 3rem;
  background-color: $primary-01;
  color:  $primary;
  border-radius: 5px;
  display: block;
  padding: 0px 15px;
}
/* End::Settings */

/* Start:: Tags */
.tags>.tag:not(:last-child) {
  margin-inline-end: 0.5rem;
}

.tags>.tag {
  margin-block-end: 0.5rem;
}

.tag {
  font-size: 0.75rem;
  background-color: var(--primary01);
  color: var(--primary-bg-color);
  border-radius: 3px;
  padding: 0 0.5rem;
  line-height: 2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tag-rounded {
  border-radius: 50px;
}

.tag-avatar {
  width: 1.5rem !important;
  height: 1.6rem !important;
  border-start-start-radius: 3px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 3px;
  margin-block-start: 0px;
  margin-inline-end: 0.5rem;
  margin-block-end: 0px;
  margin-inline-start: -0.5rem;
  background-size: cover;
}

.tag-addon:last-child {
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
}

a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s color, 0.3s;
}

.tag-addon {
  display: inline-block;
  padding: 0 0.5rem;
  color: inherit;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.06);
  margin-block-start: 0px;
  margin-inline-end: -0.5rem;
  margin-block-end: 0px;
  margin-inline-start: 0.5rem;
  text-align: center;
  min-width: 1.5rem;
}

.tag-primary {
  background-color: $primary;
  color: $white;
}

.tag-danger {
  background-color: #dc0441;
  color: $white;
}

.tag-dark {
  background-color: #000;
  color: $white;
}

.tag-addon:last-child {
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
}

.tag-success {
  background-color: #2dce89;
  color: $white;
}

.tag-warning {
  background-color: #ecb403;
  color: $white;
}

.tag-blue {
  background-color: #083dc1;
  color: $white;
}

.tag-azure {
  background-color: #45aaf2;
  color: $white;
}

.tag-indigo {
  background-color: #6574cd;
  color: $white;
}

.tag-purple {
  background-color: #aa4cf2;
  color: $white;
}

.tag-pink {
  background-color: #ec82ef;
  color: $white;
}

.tag-red {
  background-color: #dc0441;
  color: $white;
}

.tag-orange {
  background-color: #fc7303;
  color: $white;
}

.tag-yellow {
  background-color: #ecb403;
  color: $white;
}

.tag-lime {
  background-color: #7bd235;
  color: $white;
}

.tag-green {
  background-color: #2dce89;
  color: $white;
}

.tag-teal {
  background-color: #2bcbba;
  color: $white;
}

.tag-cyan {
  background-color: #116e7c;
  color: $white;
}

.tag-gray {
  background-color: #868e96;
  color: $white;
}

.tag-gray-dark {
  background-color: #34393e;
  color: $white;
}
.token.tag{
  background-color: transparent;
}
/* End:: Tags */

.card-aside-column {
  min-width: 5rem;
  width: 20%;
  flex: 0 0 20%;
  background: no-repeat center / cover;
}
.cover-image {
  background-size: cover !important;
}
.card-aside {
  flex-direction: row;
}
.services-statistics .counter-icon {
  margin-bottom: 1.5rem;
  margin-top: 14px;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  padding: 1.3rem 1.4rem;
  text-align: center;
  border-radius: 50px;
}
.about-main{
  margin-block-start: 6rem;
}
.icon-badge::before{
  content: '' !important;
} 
.jvm-zoom-btn{
  background-color: $light !important;
  color: $default-text-color !important;
}
#jvm-markers-group{
  image{
    width: 17px !important;
    height: 21px !important;
  }
}
.apexcharts-canvas .apexcharts-toolbar{
  top: -12px !important;
}
div:where(.swal2-container) input:where(.swal2-input), div:where(.swal2-container) input:where(.swal2-file), div:where(.swal2-container) textarea:where(.swal2-textarea){
  border-color: $default-border !important;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 0px !important;
}
.page-header-breadcrumb{
  margin-block: 2rem;
}
.dropzone .dz-preview:hover {
  z-index: 99 !important;
}
#sparkline-1,#sparkline-2,#sparkline-3,#sparkline-4{
  .apexcharts-grid line, .apexcharts-grid-borders line, .apexcharts-xaxis line {
    stroke: transparent;
  }
}
.echart-charts div{
  width: 100% !important;
}
#mixed-linearea .apexcharts-canvas .apexcharts-toolbar {
  top: 0px !important;
}
#donut-pattern .apexcharts-pie text{
  fill: $custom-black !important;
}
.authentication {
  .btn-icon {
    padding: 1px !important;
  }
}
@media (max-width: 764px){
    .filemanager-top{
      margin-block-start: 60px;
    }
    .filemanager-text{
      color: $default-text-color !important;
    }
}

.filemanager-text{
  color: #fff;
}