
/* start:: mail-inbox */
.mail-inbox .icons {
  width: 30px;
  height: 30px !important;
  border-radius: 5px;
  background: 0 0;
  text-align: center;
  line-height: 30px !important;
  display: block !important;
  padding: 0 !important;
  font-size: 16px;
  margin-inline-end: 8px;
}

.mail-inbox {
  .list-group-item {
    border-width: 0px !important;
  }

  .list-group-item.active {
    color: $primary !important;
    background-color: $primary-005 !important;
    border-color: var(--primary-color) !important;
  }

  .list-group-item:hover {
    color: $primary !important;
    background-color: $primary-005 !important;
    border-color: var(--primary-color) !important;
  }
}

.list-group-item {
  border: 1px solid $default-border;
}

.mail-option .btn-group a.btn,
.mail-option .chk-all {
  border: 1px solid $default-border;
  border-radius: 3px !important;
  display: inline-block;
  padding: 5px 10px;
  color: $default-text-color;
}
.wd-150 {
  width: 150px;
}

.inbox-pagination a.np-btn {
  border: 1px solid $default-border;
  border-radius: 3px !important;
  display: inline-block;
  padding: 5px 10px;
}

.inbox-pagination a.np-btn {
  margin-inline-start: 5px;
}

.table-inbox tr td i:hover {
  color: $danger;
}

.table-responsive {
  width: 100%;
}

.table-inbox tr td i {
  color: $default-body-color;
}

.table-inbox tr td .bi-star-fill:hover {
  color: $warning;
}

.mail-notification-settings, .mail-security-settings {
  width: 60%;
}

#security .choices__list--dropdown .choices__item--selectable::after,
#security .choices__list[aria-expanded] .choices__item--selectable::after{
display: none !important;
}
#security .choices__list--dropdown .choices__item--selectable,
#security .choices__list[aria-expanded] .choices__item--selectable {
padding-inline-end: 0 !important;
}

/* end:: mail-inbox */




/* Start:: mail-settings */
.mail-notification-settings,.mail-security-settings {
  width: 60%;
}
@media (max-width: 575.98px) {
  #account-settings {
    .btn-group label {
      font-size: .625rem;
    }
  }
}

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
  padding: 10px !important;
}
.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
  display: none;
}
/* End:: mail-settings */
