@media (min-width: 992px) {
  [data-nav-style="menu-click"][data-nav-layout="horizontal"] {
    &[data-theme-mode="dark"] {
      .landing-body
        .app-sidebar
        .slide.has-sub.open
        .slide-menu.child1::before {
        border-block-start-color: rgba(0, 0, 0, 0.1);
        border-inline-start-color: rgba(0, 0, 0, 0.1);
      }
      &[dir="rtl"] {
        .slide.has-sub.open .slide-menu.child1 {
          &::before {
            border-inline-end-color: var(--border);
            border-inline-start-color: transparent !important;
          }
        }
      }
    }
    .landing-body {
      .app-sidebar {
        .side-menu__item .side-menu__angle {
          color: #fff;
        }
        .slide.has-sub.open {
          .slide-menu {
            &.child1,
            &.child2,
            &.child3 {
              .slide .side-menu__item {
                .side-menu__angle {
                  color: $menu-prime-color !important;
                }
                &:hover,
                &.active {
                  color: $primary;
                  .side-menu__angle {
                    color: $primary !important;
                  }
                  &:before {
                    border-color: $primary !important;
                  }
                }
              }
            }
          }
        }

        .side-menu__item {
          border-radius: 0.25rem;
          padding: 0.3rem;
        }

        .slide-menu.child1 {
          border-radius: 0.25rem;
          padding: 0.55rem 0.3rem;
        }

        .slide-menu.child1 .side-menu__item {
          padding: 0.33rem 1.6rem;
        }

        .slide.has-sub.open .slide-menu.child1 {
          overflow: visible !important;

          &::before {
            content: "";
            position: inherit !important;
            inset-block-start: -7px;
            inset-inline-start: 10%;
            width: 13px;
            height: 13px;
            z-index: 99999;
            border: 1px solid transparent;
            border-block-start-color: var(--border);
            border-inline-start-color: var(--border);
            transform: rotate(45deg);
            background-color: $custom-white;
          }
        }
        .side-menu__item {
          padding-inline-end: 1.3rem;
          padding-inline-start: 1.3rem;
        }

        .side-menu__item.active,
        .side-menu__item:hover {
          background-color: transparent !important;
        }

        .slide-menu.child1 .slide .side-menu__item:before {
          inset-block-start: 0.838rem;
          inset-inline-start: 0.65rem;
        }

        .side-menu__item:hover .side-menu__angle {
          color: $primary !important;
        }
      }
      .app-sidebar {
        .side-menu__label {
          color: #fff;
          opacity: 0.8;
        }
        .side-menu__item {
          &.active,
          &:hover {
            background-color: $primary;
            color: $primary;

            .side-menu__label,
            .side-menu__angle {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
  .flex-none {
    flex: none;
  }
}

.landing-body {
  background-color: $custom-white;
  .app-sidebar {
    border-bottom: 0;
    .side-menu__item {
      padding: 0.8rem 1rem;

      &.active,
      &:hover {
        color: $primary !important;
      }
    }
  }

  .app-sidebar.sticky.sticky-pin {
    .side-menu__item.active {
      font-weight: normal;

      .side-menu__label {
        color: $primary !important;
      }
      .side-menu__angle {
        color: $primary !important;
      }
    }
  }

  .accordion.accordion-primary .accordion-button.collapsed:after {
    background-color: $primary-01;
    color: $primary;
  }

  .featur-icon {
    width: 75px;
    height: 75px;
    padding: 14px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }

  .feature-logos {
    display: flex;
    justify-content: center;
  }

  .landing-Features {
    position: relative;
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    background-image: url("../images/media/landing/2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 9;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(var(--primary-rgb), 0.9);
      top: 0px;
      z-index: -1;
    }
  }

  .app-sidebar .slide-menu.child1 .side-menu__item.active:before {
    width: 0.35rem;
    height: 0.35rem;
    border: 0px;
    border-radius: 1.0625rem;
    background-color: $primary;
    inset-block-start: 12px !important;
  }

  @media (min-width: 992px) {
    .app-sidebar {
      inset-block-start: 0;
      padding: 0.5rem 0;
      height: auto !important;
      background-color: $primary-01;
      box-shadow: none;
      border-inline-end: 0;

      .main-sidebar {
        height: auto !important;
        width: 100%;
      }

      &.sticky.sticky-pin {
        background-color: $custom-white;
        box-shadow: 0 0.25rem 1rem $black-1;

        .side-menu__item {
          .side-menu__angle {
            color: $menu-prime-color;
          }
          &:hover {
            .side-menu__angle {
              color: $primary !important;
            }
          }
        }
        &.app-sidebar .side-menu__label {
          color: $custom-black;
        }

        .landing-logo-container .horizontal-logo {
          .desktop-white {
            display: none;
          }

          .desktop-logo {
            display: block;
          }
        }
        &.app-sidebar .side-menu__item:hover {
          .side-menu__label {
            color: $primary !important;
          }
        }
      }
      &.app-sidebar .slide.has-sub.open .slide-menu.child1::before {
        border-block-start-color: $default-border !important;
        border-inline-start-color: $default-border !important;
      }
    }

    .app-header {
      display: none;
    }

    .main-sidebar-header {
      display: block !important;
    }

    .main-menu-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .slide-left,
      .slide-right {
        display: none;
      }
    }

    .main-content {
      padding: 0;
    }

    .landing-logo-container {
      .horizontal-logo .header-logo {
        .desktop-logo {
          display: none;
        }
        .desktop-white {
          display: block;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .app-sidebar .side-menu__item.active,
    .app-sidebar .side-menu__item:hover {
      background-color: transparent;
    }
    .main-menu-container .main-menu {
      padding-inline: 20px !important;
      width: 100%;
    }
    .app-sidebar .slide-menu {
      padding-inline-start: 1rem;
    }

    .app-sidebar {
      .slide {
        padding: 0;
      }
    }

    .landing-logo-container {
      .horizontal-logo .header-logo {
        .desktop-logo {
          display: none !important;
        }

        .desktop-white {
          display: none !important;
        }
      }
    }
  }

  .main-menu-container .main-menu {
    padding-inline-start: 0;
  }

  .app-content {
    margin-block-start: 4rem;
  }

  .section {
    padding: 3.375rem 0;
    background-size: cover;
    position: relative;
  }

  .landing-banner {
    position: relative;
    width: 100%;
    // height: 37rem;
    inset-block-start: 0;
    background-image: url("../images/media/landing/banner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $primary-09;
    }

    .main-banner-container {
      padding: 4rem 3rem;
    }

    .landing-banner-heading {
      line-height: 1.25;
      text-shadow: 1px 1px $black-1;
      font-size: 3rem;
      font-weight: 600;
      color: $white;
    }
  }

  @media (max-width: 767.98px) {
    .landing-main-image {
      display: none;
    }

    .landing-banner {
      // height: 34rem;

      .main-banner-container {
        padding: 1rem;
      }
    }
  }

  @media (max-width: 1115.98px) {
    .landing-main-image {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  // @media (max-width: 400px) {
  //   .landing-body .landing-banner {
  //     height: 45rem;
  //   }
  // }

  @media (max-width: 480px) {
    .landing-banner {
      .section {
        padding: 2.375rem 0;
      }
    }
  }

  .landing-main-image {
    position: relative;
    z-index: 10;

    img {
      z-index: 11;
      position: relative;
      width: 27.063rem;
      margin-block-start: 25px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 24.5rem;
      height: 24.5rem;
      // background-color: $black-1;
      opacity: 0.3;
      inset-inline-start: 5rem;
      inset-block-start: 0rem;
      border-radius: 50% 50px 50% 50%;
      box-shadow: 0 3rem 10rem $white-1 !important;
    }

    &:after {
      content: "";
      position: absolute;
      width: 12.5rem;
      height: 12.5rem;
      background-color: transparent;
      border: 0.125rem dashed $primary-05;
      opacity: 0.3;
      inset-inline-start: 5.1rem;
      inset-block-end: 4.4rem;
    }
  }

  .landing-page-wrapper {
    position: relative;
    min-height: calc(100vh - 3.4rem);
  }

  .main-landing-image {
    width: 37.5rem;
    padding: 0.5rem;
    border-radius: $default-radius;
    background-color: $primary-01;
  }

  .brand-img-white {
    display: block;
  }

  .brand-img-light {
    display: block;
  }

  .app-sidebar .side-menu__label {
    font-family: 600;
  }

  .landing-section-heading {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 60%;
      height: 0.25rem;
      background-image: linear-gradient(
        to right,
        $success,
        rgba(var(--success-rgb), 0.1)
      );
      opacity: 0.5;
      inset-block-start: -0.625rem;
      border-radius: 3.125rem;
      inset-inline-end: 0;
    }
  }

  .landing-missions,
  .landing-missions-dark {
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05) !important;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.15rem;
      inset-block-start: 0;
      inset-inline-start: 0;
      border-radius: 3.125rem;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .customize-image img {
    width: 30.5rem;
    height: 20rem;
    margin-block-start: 20px;
    padding: 15px;
    position: relative;
  }

  .landing-footer {
    background-color: rgb(35, 35, 35);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .landing-footer-list {
      li {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .landing-main-footer {
    background-color: rgb(35, 35, 35);
  }

  .landing-testimonials .swiper-pagination-bullet {
    background: $primary !important;

    &:active {
      background: $primary !important;
    }
  }

  .team-card {
    transition: box-shadow 0.2s ease-in;

    &:hover {
      box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.2s ease-in;
    }

    .team-avatar {
      box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.05);
    }
  }

  .btn-close {
    padding: 1.5rem;
    right: -0.875rem;
  }

  .section-bg {
    background-color: $default-body-color;
  }
}

[data-theme-mode="dark"] {
  .landing-body {
    @media (min-width: 992px) {
      .main-menu-container {
        .landing-logo-container {
          .horizontal-logo .header-logo {
            .desktop-logo {
              display: none !important;
            }
          }
        }
      }
    }

    @media (max-width: 991.98px) {
      .app-header {
        .main-header-container {
          .horizontal-logo .header-logo {
            .toggle-logo {
              display: none;
            }

            .toggle-dark {
              display: block;
            }
          }
        }
      }
    }

    .landing-trusted-companies {
      img {
        filter: imvert(1);
      }
    }

    .team-card {
      &:hover {
        box-shadow: 0 0.25rem 1rem 0 rgba(255, 255, 255, 0.05);
      }

      .team-avatar {
        box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0.05);
      }
    }
    .app-sidebar.sticky.sticky-pin {
      .landing-logo-container .horizontal-logo {
        .desktop-white {
          display: block;
        }
      }
    }
  }

  .brand-img-light {
    display: block;
  }

  .brand-img-white {
    display: none;
  }

  .section-bg {
    background-color: $black-1;
  }
}

@media (max-width: 420px) {
  .landing-body .landing-banner {
    // height: 37.5rem;
    .main-banner-container {
      padding: 1rem;
    }

    .landing-banner-heading {
      font-size: 2rem;
    }
  }
}
@media (max-width: 992px) {
  .landing-body {
    .app-sidebar .slide-menu.child1 li,
    .app-sidebar .slide-menu.child2 li,
    .app-sidebar .slide-menu.child3 li {
      padding: 0;
      -webkit-padding-start: 1.5rem;
      padding-inline-start: 0;
      position: relative;
    }
  }
}
[dir="rtl"] {
  .landing-body {
    .testimonial-card,.featured-card-1 {
      direction: rtl;
    }
  }
}

.landing-body {
  background-color: $custom-white;
  .svg-icons {
    width: 25px;
    height: 25px;
  }
  .svg-icons-lg {
    width: 35px;
    height: 35px;
  }
  .bg-black-light{
   background-color: $black-2;
  }
  .pricing-tabs .pri-tabs-heading {
    padding: 15px;
    text-align: center;
    margin: 0 auto;
    display: inline-table;
  }
  .pricing-tabs .nav-price li a {
    padding: 10px 20px 10px 20px;
    color: $default-text-color;
    background: $custom-white;
    border: 1px solid $default-border;
  }
  .pricing-card.advanced {
    box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
  }
 .pricing-tabs .nav-price li .active {
      background: $primary !important;
      color: #fff;
  }
}