/* Start:: Breadcrumb Styles */

.page-header-breadcrumb {
  .breadcrumb-item+.breadcrumb-item::before {
    content: "/";
    font-family: "tabler-icons" !important;
    color: #fff;
    opacity: 0.5;
  }
}
.breadcrumb {
  .breadcrumb-item {
    a {
      color: $primary;
    }

    &.active {
      color: $default-text-color;
      font-weight: 600;
    }
  }
}
.breadcrumb-item+.breadcrumb-item::before {
  color: $text-muted;
}
.breadcrumb-example1 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea65");
    font-family: "tabler-icons" !important;
  }
}
.breadcrumb-style1 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea1c");
    font-family: "tabler-icons" !important;
  }
}
.breadcrumb-style2 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea61");
    font-family: "tabler-icons" !important;
  }
}
.embedded-breadcrumb:before {
  opacity: 0.7;
}
[data-theme-mode="dark"] {
  .embedded-breadcrumb:before {
    filter: invert(1);
  }
}
[dir="rtl"] {
  
.breadcrumb-example1 {
  .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea64");
  }
}
.breadcrumb-style1 {
  .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea1b");
  }
}
.breadcrumb-style2 {
  .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "\ea60");
  }
}
.embedded-breadcrumb:before {
  transform: scaleX(-1);
  padding-inline-start: 0.5rem;
  padding-inline-end: 0rem !important;
}
}
/* End:: Breadcrumb Styles */